@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  color: #505967;
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}



@property --rotate {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: false;
}

.heroButton {
  background: blue;
  position: relative;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.heroButton::before {
  animation: none;
  opacity: 0;
  content: "";
  width: 100%;
  height: 103%;
  border-radius: 50px;
  background-image: linear-gradient(var(--rotate),
      var(--gray) 43%,
      var(--dark));
  position: absolute;
  z-index: -1;
  top: -2px;
  left: 3px;
  padding: px;
}

.heroButton::after {
  animation: none;
  opacity: 0;
  position: absolute;
  content: "";
  top: 1px;
  padding: 10px;
  border-radius: 50px;
  left: -10px;
  right: 0;
  z-index: -1;
  height: 110%;
  width: 115%;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(var(--rotate), white 43%, var(--gray));
  transition: opacity 0.5s;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: rotate360 2s linear infinite;
}

@keyframes rotate360 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.hero-button {
  background: #505967;
  position: relative;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.hero-button-container::before {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.327);
  filter: blur(15px);
  z-index: -1;
  scale: 0;
  transition: all 300ms ease-in;

}

.hero-button::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 150px;
  width: 40px;
  background: linear-gradient(90deg,
      rgba(92, 92, 92, 0.4) 0%,
      rgb(205, 203, 203) 30%,
      rgb(205, 203, 203) 70%,
      rgba(92, 92, 92, 0.4) 100%);
  box-shadow:
    10px 10px 15px rgba(92, 92, 92, 0.4),
    -10px 10px 15px rgba(92, 92, 92, 0.4);
  transform: translate(0);
  scale: 0;
  transform-origin: top center;
  z-index: -1;
  animation: rotate360 5s linear infinite paused;
  transition: all 300ms ease-in;
}

.hero-button-container:before {
  scale: 1;
}

.hero-button-container .hero-button::after {
  animation-play-state: running;
  scale: 1;
  transition: transform 300ms 300ms ease-in;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hero-button::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 110px;
  width: 40px;
  background: linear-gradient(90deg,
      rgba(92, 92, 92, 0.4) 0%,
      rgb(205, 203, 203) 30%,
      rgb(205, 203, 203) 70%,
      rgba(92, 92, 92, 0.4) 100%);
  filter: blur(14px);
  box-shadow:
    10px 10px 15px rgba(92, 92, 92, 0.4),
    -10px 10px 15px rgba(92, 92, 92, 0.4);
  transform: translate(0);
  scale: 0;
  transform-origin: top center;
  z-index: -1;
  animation: rotate360 3s linear infinite paused;
  transition: all 300ms ease-in;
}

.gradient-button {
  border: none;
  background-color: red;
  position: relative;
}

.gradient-button::after {
  content: "";
  position: absolute;
  height: 105%;
  width: 102%;
  border: inherit;
  background-image: linear-gradient(90deg, #d54129, #0061ff);
  z-index: -1;
  border-radius: inherit;
}




/* styles.css */
.bg-hero {
  background-image: url('./assets/bg-hero.svg');
  background-size: cover;
  background-position: center;

}

.bg-hero2 {
  background-image: url('./assets/bg-hero.svg');
  background-size: cover;
  background-position: center;

}


.bg-slides {
  background-image: url('./assets/bg-slides.svg');
  background-size: cover;
  background-position: left;
  background-repeat: repeat;

}

.bg-ladycomputer {
  background-image: url('./assets/ladycomputer.svg');
  background-size: cover;
  background-position: center;

}

.bg-who-we-are {
  background-image: url('./assets/who-we-are.svg');
  background-size: cover;
  background-position: center;

}

.bg-pos-section {
  background-image: url('./assets/pos1.svg');
  background-size: cover;
  background-position: center;

}


.bg-offers {
  background-image: url('./assets/bg-offers.svg');
  background-size: cover;
  background-position: center;

}

.bg-coin {
  background-image: url('./assets/bg-coin.svg');
  background-size: cover;
  background-position: center;

}


.bg-business1 {
  background-image: url('./assets/business1.svg');
  background-size: cover;
  background-position: center;
  border-radius: 35px;

}


.bg-business2 {
  background-image: url('./assets/business2.svg');
  background-size: cover;
  background-position: center;
  border-radius: 35px;

}

.bg-business3 {
  background-image: url('./assets/business3.svg');
  background-size: cover;
  background-position: center;
  border-radius: 35px;

}

.bg-business4 {
  background-image: url('./assets/business4.svg');
  background-size: cover;
  background-position: center;
  border-radius: 35px;

}

/* ANIMATED TEXT */
.fade-in-text {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}